import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import BtmFootInplay from "../assets/images/icons/home/bet.png";
import BtmFootSports from "../assets/images/icons/home/btmFootSports.png";
import BtmFootHome from "../assets/images/icons/home/btmFootHome.png";
import BtmFootCasino from "../assets/images/icons/home/spade.png";
import BtmFootProfile from "../assets/images/icons/home/user.png";
import Deposit from "../assets/images/icons/home/wallet.png";
import { useSelector } from "react-redux";
import AviatorIcon from "../assets/images/icons/home/jet-plane.png";
import ExchangeIconFoot from "../assets/images/icons/home/cricketfoot.png";

export default function BottomNav() {
  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const { isAuth } = useSelector((state) => state.auth);

  const footerPages = [
    "sportsbook",
    "inplaySportsbook",
    "home",
    "live-dealer",
    "profile",
    "sportsbook",
    "casino/evolution",
    "casino/ezugi",
    "public-inplay",
    "public-sports",
    "/",
    "sports",
    "gatewaylist",
    "/sports/Cricket",
  ];
  useEffect(() => {
    const matchedValue = footerPages.find((f) => {
      if (f === "public-sports") {
        return location?.pathname?.split("/")?.[1] === "public-sports";
      } else {
        return location?.pathname.includes(f);
      }
    });
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  return (
    <div className="bottom-menu">
      <footer className="d-lg-none">
        <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100">
          <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
            <li className="inplay">
              <a
                className={
                  activePage === "casino/ezugi" || activePage === "casino/ezugi"
                    ? "active"
                    : ""
                }
                href={isAuth ? "/casino/ezugi" : "/sign-in"}
              >
                <div className="imgBox">
                  <img src={BtmFootInplay} alt="footer nav icon" />
                </div>
                <span>Ezugi</span>
              </a>
            </li>
            <li className="casino">
              <a
                className={activePage === "casino/evolution" ? "active" : ""}
                href={isAuth ? "/casino/evolution" : "/casino/evolution"}
              >
                <div className="imgBox">
                  <img src={BtmFootCasino} alt="footer nav icon" />
                </div>
                <span>Evolution</span>
              </a>
            </li>

            <li className="home">
              <a
                className={
                  activePage === "sports" || activePage === "/" ? "active" : ""
                }
                href="/sports"
              >
                <div className="imgBox">
                  <img src={BtmFootHome} alt="footer nav icon" />
                </div>
                <span>Home</span>
              </a>
            </li>
            <li className="profile">
              <a
                className={activePage === "/sports/Cricket" ? "active" : ""}
                href="/sports/Cricket"
              >
                <div className="imgBox">
                  <img src={ExchangeIconFoot} alt="footer nav icon" />
                </div>
                <span>Exchange</span>
              </a>
            </li>
            <li className="profile">
              <a
                className={activePage === "gatewaylist" ? "active" : ""}
                href="/casino/spribe/aviator"
              >
                <div className="imgBox">
                  <img src={AviatorIcon} alt="footer nav icon" />
                </div>
                <span>Aviator</span>
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
}
