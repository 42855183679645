import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../../containers/Header";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import BottomNav from "../../../../containers/BottomNav";

import Ezugi from "../../../../assets/images/casino/provider/Ezugi.jpg";
import Evolution from "../../../../assets/images/casino/provider/Evolution.jpg";
import Vivogaming from "../../../../assets/images/casino/provider/Vivogaming.jpg";
import Supernova from "../../../../assets/images/casino/provider/Supernova.jpg";
import WorldCasino from "../../../../assets/images/casino/provider/WorldCasino.jpg";
import XPG from "../../../../assets/images/casino/provider/XPG.jpg";
import virtualsports from "../../../../assets/images/casino/provider/virtualsports.jpg";
import OneTouch from "../../../../assets/images/casino/provider/OneTouch.jpg";
import Paragmatic from "../../../../assets/images/casino/provider/Paragmatic.jpg";
import Qtech from "../../../../assets/images/casino/provider/Qtech.jpg";

import BackIcon from "../../../../assets/svg/BackIcon";

const LiveDealerPublic = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />

      <main className="main">
        <div className="container">
          <div className="live-deal-section">
            <div className="pagesHeading">
              <h2 className="sectionTitle border-0">Live Dealer</h2>
              <div className="back-link" onClick={() => navigate(-1)}>
                Back <BackIcon />
              </div>
            </div>
            <ul>
              <li>
                <a href="/casino/ezugi">
                  <img src={Ezugi} alt="Providers Logo" />
                </a>
              </li>
              <li>
                <a href="/casino/evolution">
                  <img src={Evolution} alt="Providers Logo" />
                </a>
              </li>
              <li>
                <a href="/casino/vivo">
                  <img src={Vivogaming} alt="Providers Logo" />
                </a>
              </li>
              <li>
                <a href="/casino/supernova">
                  <img src={Supernova} alt="Providers Logo" />
                </a>
              </li>
              <li>
                <a href="/casino/worldcasino">
                  <img src={WorldCasino} alt="Providers Logo" />
                </a>
              </li>
              <li>
                <a href="/casino/xpg">
                  <img src={XPG} alt="Providers Logo" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </main>

      <Footer />
      <BottomNav />
    </>
  );
};

export default LiveDealerPublic;
