export const pageUrlGenerator = (page) =>
  `${window.location.protocol}//${window.location.host}${page}`;

export const casinoSection = {
  popularGames: {
    UltimateRoulette: {
      redirectUrl: "/casino/ezg-ultimate-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/UltimateRoulette.png",
      alt: "",
      name: "Ultimate Roulette",
      providerName: "Ezugi",
    },
    AndarBahar: {
      redirectUrl: "/casino/ezg-andar-bahar",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/AndarBahar.png",
      alt: "",
      name: "Andar Bahar",
      providerName: "Ezugi",
    },
    SpeedRoulette: {
      redirectUrl: "/casino/ezg-speed-roulette",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/SpeedRoulette.png",
      alt: "",
      name: "Speed Roulette",
      providerName: "Ezugi",
    },
    BetonTeenPatti: {
      redirectUrl: "/casino/ezg-bet-on-teen-patti",
      code: "aviator",
      casino: "spribe",
      provider: "aviator",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/BetonTeenPAtti.png",
      alt: "",
      name: "Bet on Teen Patti",
      providerName: "Ezugi",
    },
    TeenPatti3Card: {
      redirectUrl: "/casino/ezg-teen-patti-3-card",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/TeenPatti3Card.png",
      alt: "",
      name: "Teen Patti 3 Card",
      providerName: "Ezugi",
    },
    DragonTiger: {
      redirectUrl: "/casino/ezg-dragon-tiger",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/DragonTiger1.png",
      alt: "",
      name: "Dragon Tiger",
      providerName: "Ezugi",
    },
    Lucky7: {
      redirectUrl: "/casino/ezg-lucky-7",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/Lucky7.png",
      alt: "",
      name: "Lucky 7 ",
      providerName: "Ezugi",
    },
    NamasteRoulette: {
      redirectUrl: "/casino/ezg-namaste-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/NamasteRoulette.png",
      alt: "",
      name: "Namaste Roulette",
      providerName: "Ezugi",
    },
    UltimateAndarBahar: {
      redirectUrl: "/casino/ezg-ultimate-andar-bahar",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/UltimateAndarBahar.png",
      alt: "",
      name: "Ultimate Andar Bahar",
      providerName: "Ezugi",
    },
    AutoRoulette: {
      redirectUrl: "/casino/ezg-auto-roulette",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/AutoRoulette.png",
      alt: "",
      name: "Auto Roulette",
      providerName: "Ezugi",
    },
    CricketWar: {
      redirectUrl: "/casino/ezg-cricket-war",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/CricketWar.png",
      alt: "",
      name: "CricketWar",
      providerName: "Ezugi",
    },
    BaccaratA: {
      redirectUrl: "/casino/ezgevo-baccarat-a",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/BaccaratA.png",
      alt: "",
      name: "Baccarat A",
      providerName: "Ezugi",
    },
    DiamondRoulette: {
      redirectUrl: "/casino/ezg-diamond-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/DiamondRoulette.png",
      alt: "",
      name: "Diamond Roulette",
      providerName: "Ezugi",
    },
    VIPDiamondBlackjack: {
      redirectUrl: "/casino/ezg-vip-diamond-blackjack",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/VIPDiamondBlackjack.png",
      alt: "",
      name: "VIP Diamond Blackjack",
      providerName: "Ezugi",
    },
    UltimateSicBo: {
      redirectUrl: "/casino/ezg-ultimate-sic-bo",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/UltimateSicbo.png",
      alt: "",
      name: "Ultimate Sic Bo",
      providerName: "Ezugi",
    },
    SpeedAutoRoulette: {
      redirectUrl: "/casino/ezg-speed-auto-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/SpeedAutoRoulette.png",
      alt: "",
      name: "Speed Auto Roulette",
      providerName: "Ezugi",
    },
    BaccaratB: {
      redirectUrl: "/casino/ezgevo-baccarat-b",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/BaccaratB.png",
      alt: "",
      name: "Baccarat B",
      providerName: "Ezugi",
    },
    OneDayTeenPatti: {
      redirectUrl: "/casino/ezg-one-day-teen-patti",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/OneDayTeenPAtti.png",
      alt: "",
      name: "One Day Teen Patti",
      providerName: "Ezugi",
    },
    SpeedCricketBaccarat: {
      redirectUrl: "/casino/Baccarat",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/SpeedCricketBaccarat.png",
      alt: "",
      name: "Speed Cricket Baccarat",
      providerName: "Ezugi",
    },
    Cards32: {
      redirectUrl: "/casino/ezg-32-cards",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/32cards.png",
      alt: "",
      name: "Cards32",
      providerName: "Ezugi",
    },
    CasinoHoldem: {
      redirectUrl: "/casino/ezg-casino-holdem",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/CasinoHoldem.png",
      alt: "",
      name: "Casino Holdem",
      providerName: "Ezugi",
    },

    // evolution
    CrazyTime: {
      redirectUrl: "/casino/ezgevo-crazy-time",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/populargames/crazytime.png",
      alt: "",
      name: "Crazy Time",
      providerName: "Evolution",
    },
    LightningRoulette: {
      redirectUrl: "/casino/ezgevo-lightning-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/populargames/Lightning-Roulette.png",
      alt: "",
      name: "Lightning Roulette",
      providerName: "Evolution",
    },
    TurkishLightningRoulette: {
      redirectUrl: "/casino/ezgevo-turkish-lightning-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl:
        "./images/casino/evolution/populargames/Turkce-Lightning-Rulet.png",
      alt: "",
      name: "Turkish Lightning Roulette",
      providerName: "Evolution",
    },
    SpeedAutoRoulette: {
      redirectUrl: "/casino/ezgevo-speed-auto-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/populargames/speedautoroulette.png",
      alt: "",
      name: "Speed Auto Roulette",
      providerName: "Evolution",
    },
    Megaball: {
      redirectUrl: "/casino/ezgevo-mega-ball",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/populargames/Megaball.png",
      alt: "",
      name: "Mega Ball",
      providerName: "Evolution",
    },
    SuperSicBo: {
      redirectUrl: "/casino/ezgevo-super-sic-bo",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/populargames/Supersicbo.png",
      alt: "",
      name: "Super Sic Bo",
      providerName: "Evolution",
    },
    LightningDice: {
      redirectUrl: "/casino/ezgevo-lightning-dice",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/populargames/LightningDice.png",
      alt: "",
      name: "Lightning Dice",
      providerName: "Evolution",
    },
    DreamCatcher: {
      redirectUrl: "/casino/ezgevo-dream-catcher",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/populargames/Dreamcatcher.png",
      alt: "",
      name: "Dream Catcher",
      providerName: "Evolution",
    },
    DragonTiger1: {
      redirectUrl: "/casino/ezgevo-dragon-tiger-evo",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/populargames/dragontiger.png",
      alt: "",
      name: "Dragon Tiger",
      providerName: "Evolution",
    },
    SpeedBaccaratA: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-a",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/populargames/SpeedBaccaratA.png",
      alt: "",
      name: "Speed Baccarat A",
      providerName: "Evolution",
    },
    DeadorAlive: {
      redirectUrl: "/casino/ezgevo-dead-or-alive-saloon",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/populargames/Deadoralivesaloon.png",
      alt: "",
      name: "Dead or Alive: Saloon",
      providerName: "Evolution",
    },
  },

  AndarBahar: {
    UltimateAndarBahar: {
      redirectUrl: "/casino/ezg-ultimate-andar-bahar",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/UltimateAndarBahar.png",
      alt: "",
      name: "Ultimate Andar Bahar",
      providerName: "Ezugi",
    },
    CasinoMarinaAndarBahar: {
      redirectUrl: "/casino/ezg-casino-marina-andar-bahar",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/OTTAndarBahar.png",
      alt: "",
      name: "OTT Andar Bahar",
      providerName: "Ezugi",
    },
    AndarBahar: {
      redirectUrl: "/casino/ezg-ultimate-andar-bahar",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/AndarBahar.png",
      alt: "",
      name: "Andar Bahar",
      providerName: "Ezugi",
    },
  },

  roulettetab: {
    // ezugi
    UltimateRoulette: {
      redirectUrl: "/casino/ezg-ultimate-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/UltimateRoulette.png",
      alt: "",
      name: "Ultimate Roulette",
      providerName: "Ezugi",
    },
    SpeedRoulette: {
      redirectUrl: "/casino/ezg-speed-roulette",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/SpeedRoulette.png",
      alt: "",
      name: "Speed Roulette",
      providerName: "Ezugi",
    },
    NamasteRoulette: {
      redirectUrl: "/casino/ezg-namaste-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/NamasteRoulette.png",
      alt: "",
      name: "Namaste Roulette",
      providerName: "Ezugi",
    },
    DiamondRoulette: {
      redirectUrl: "/casino/ezgevo-american-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/casino/ezugi/roulette/DiamondRoulette.png",
      alt: "",
      name: "Diamond Roulette",
      providerName: "Ezugi",
    },
    PrestigeAutoRoulette: {
      redirectUrl: "/casino/	ezg-prestige-auto-roulette",
      code: "221002",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/casino/ezugi/roulette/PrestigeAutoRoulette.png",
      alt: "",
      name: "Prestige Auto Roulette",
      providerName: "Ezugi",
    },
    CricketAutoRoulette: {
      redirectUrl: "/casino/	ezg-cricket-auto-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/casino/ezugi/roulette/CricketAutoRoulette.png",
      alt: "",
      name: "Cricket Auto Roulette",
      providerName: "Ezugi",
    },
    ItalianRoulette: {
      redirectUrl: "/casino/ezg-italian-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/roulette/ItalianRoulette.png",
      alt: "",
      name: "Italian Roulette",
      providerName: "Ezugi",
    },
    SpanishRoulette: {
      redirectUrl: "/casino/ezg-spanish-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/roulette/SpanishRoulette.png",
      alt: "",
      name: "Spanish Roulette",
      providerName: "Ezugi",
    },
    TurkishRoulette: {
      redirectUrl: "/casino/ezg-turkish-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/roulette/TurkishRoulette.png",
      alt: "",
      name: "Turkish Roulette",
      providerName: "Ezugi",
    },
    RussianRoulette: {
      redirectUrl: "/casino/ezg-russian-roulette",
      code: "1000103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/roulette/RussianRoulette.png",
      alt: "",
      name: "Russian Roulette",
      providerName: "Ezugi",
    },
    PortomasoRoulette2: {
      redirectUrl: "/casino/ezg-russian-roulette",
      code: "1000103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/roulette/PortomosRoulette2.png",
      alt: "",
      name: "Portomaso Roulette 2",
      providerName: "Ezugi",
    },
    CasinoMarinaRoulette1: {
      redirectUrl: "/casino/ezg-casino-marina-roulette-1",
      code: "1000103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/roulette/CasinoMarinaRoulette1.png",
      alt: "",
      name: "Casino Marina Roulette 1",
      providerName: "Ezugi",
    },
    CasinoMarinaRoulette2: {
      redirectUrl: "/casino/ezg-casino-marina-roulette-2",
      code: "1000103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/roulette/CasinoMarinaRoulette2.png",
      alt: "",
      name: "Casino Marina Roulette 2",
      providerName: "Ezugi",
    },
    DiamondRoulette: {
      redirectUrl: "/casino/ezg-diamond-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/DiamondRoulette.png",
      alt: "",
      name: "Diamond Roulette",
      providerName: "Ezugi",
    },
    SpeedAutoRoulette: {
      redirectUrl: "/casino/ezg-speed-auto-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/SpeedAutoRoulette.png",
      alt: "",
      name: "Speed Auto Roulette",
      providerName: "Ezugi",
    },
    Oracle360: {
      redirectUrl: "/casino/ezg-oracle-360-roulette",
      code: "1000103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/roulette/OracleRoulette360.png",
      alt: "",
      name: "Oracle 360",
      providerName: "Ezugi",
    },
    AutoRoulette: {
      redirectUrl: "/casino/ezg-auto-roulette",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/AutoRoulette.png",
      alt: "",
      name: "Auto Roulette",
      providerName: "Ezugi",
    },

    // evolution
    LightningRoulette: {
      redirectUrl: "/casino/ezgevo-lightning-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/populargames/Lightning-Roulette.png",
      alt: "",
      name: "Lightning Roulette",
      providerName: "Evolution",
    },
    TurkishLightningRoulette: {
      redirectUrl: "/casino/ezgevo-turkish-lightning-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl:
        "./images/casino/evolution/populargames/Turkce-Lightning-Rulet.png",
      alt: "",
      name: "Turkish Lightning Roulette",
      providerName: "Evolution",
    },
    SpeedAutoRoulette: {
      redirectUrl: "/casino/ezgevo-speed-auto-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/populargames.jpg",
      alt: "",
      name: "Speed Auto Roulette",
      providerName: "Evolution",
    },
    XXXtremeLightningRoulette: {
      redirectUrl: "/casino/ezgevo-xxxtreme-lightning-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl:
        "./images/casino/evolution/roulette/XXXtreme-Lightning-Roulette.png",
      alt: "",
      name: "XXXtreme Lightning Roulette",
      providerName: "Evolution",
    },
    InstantRoulette: {
      redirectUrl: "/casino/ezgevo-instant-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/roulette/instantroulette.png",
      alt: "",
      name: "Instant Roulette",
      providerName: "Evolution",
    },
    SpeedAutoRoulette: {
      redirectUrl: "/casino/ezgevo-speed-auto-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/roulette/Speed-Auto-Roulette.png",
      alt: "",
      name: "Speed Auto Roulette",
      providerName: "Evolution",
    },
    AmericanRoulette: {
      redirectUrl: "/casino/ezgevo-american-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/roulette/American-Roulette.png",
      alt: "",
      name: "American Roulette",
      providerName: "Evolution",
    },
    ImmersiveRoulette: {
      redirectUrl: "/casino/ezgevo-immersive-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/roulette/Immersive-Roulette.png",
      alt: "",
      name: "Immersive Roulette",
      providerName: "Evolution",
    },
    VIPRoulette: {
      redirectUrl: "/casino/ezgevo-vip-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/roulette/VIP-Roulette.png",
      alt: "",
      name: "VIP Roulette",
      providerName: "Evolution",
    },
    DoubleBallRouette: {
      redirectUrl: "/casino/ezgevo-double-ball-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/roulette/Double-Ball-Rouette.png",
      alt: "",
      name: "Double Ball Rouette",
      providerName: "Evolution",
    },
  },

  dragontiger: {
    DragonTiger: {
      redirectUrl: "/casino/ezg-dragon-tiger",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/DragonTiger1.png",
      alt: "",
      name: "Dragon Tiger",
      providerName: "Ezugi",
    },
    DragonTiger1: {
      redirectUrl: "/casino/ezgevo-dragon-tiger-evo",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/populargames/dragontiger.png",
      alt: "",
      name: "Dragon Tiger",
      providerName: "Evolution",
    },
  },

  baccarattab: {
    CricketWar: {
      redirectUrl: "/casino/ezg-cricket-war",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/CricketWar.png",
      alt: "",
      name: "CricketWar",
      providerName: "Ezugi",
    },
    BaccaratA: {
      redirectUrl: "/casino/ezgevo-baccarat-a",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/BaccaratA.png",
      alt: "",
      name: "Baccarat A",
      providerName: "Ezugi",
    },
    BaccaratB: {
      redirectUrl: "/casino/ezgevo-baccarat-b",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/BaccaratB.png",
      alt: "",
      name: "Baccarat B",
      providerName: "Ezugi",
    },
    SpeedCricketBaccarat: {
      redirectUrl: "/casino/ezg-speed-cricket-baccarat",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/SpeedCricketBaccarat.png",
      alt: "",
      name: "Speed Cricket Baccarat",
      providerName: "Ezugi",
    },
    BaccaratC: {
      redirectUrl: "/casino/ezg-vip-fortune-baccarat",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/baccarat/BaccaratC.png",
      alt: "",
      name: "BaccaratC",
      providerName: "Ezugi",
    },
    CasinoMarinaBaccaratD: {
      redirectUrl: "/casino/ezg-casino-marina-baccarat-4",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/baccarat/casinomarinabaccarat3.png",
      alt: "",
      name: "Casino Marina Baccarat D",
      providerName: "Ezugi",
    },
    CasinoMarinaBaccaratC: {
      redirectUrl: "/casino/ezg-casino-marina-baccarat-3",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/baccarat/casinomarinabaccaratc.png",
      alt: "",
      name: "Casino MarinaBaccarat C",
      providerName: "Ezugi",
    },
    CasinoMarinaBaccaratA: {
      redirectUrl: "/casino/ezg-casino-marina-baccarat-1",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/baccarat/casinomarinabaccaratA.png",
      alt: "",
      name: "Casino Marina Baccarat A",
      providerName: "Ezugi",
    },
    CasinoMarinaBaccaratB: {
      redirectUrl: "/casino/ezg-casino-marina-baccarat-2",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/baccarat/casinomarinabaccaratb.png",
      alt: "",
      name: "Casino Marina Baccarat B",
      providerName: "Ezugi",
    },
    NocommissionBaccarat: {
      redirectUrl: "/casino/ezg-no-commission-baccarat",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/baccarat/nocommissionbaccarat.png",
      alt: "",
      name: "No Commission Baccarat",
      providerName: "Ezugi",
    },
    Super6baccarat: {
      redirectUrl: "/casino/ezg-super-6-baccarat",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/baccarat/super6baccarat.png",
      alt: "",
      name: "Super 6 Baccarat",
      providerName: "Ezugi",
    },
    KnockOutBaccarat: {
      redirectUrl: "/casino/ezg-knockout-baccarat",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/baccarat/kcockoutbaccarat.png",
      alt: "",
      name: "Knockout Baccarat",
      providerName: "Ezugi",
    },
    KnockOutBaccarat: {
      redirectUrl: "/casino/ezg-knockout-baccarat",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/baccarat/kcockoutbaccarat.png",
      alt: "",
      name: "Knockout Baccarat",
      providerName: "Ezugi",
    },

    // evolution
    SpeedBaccaratA: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-a",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/populargames/SpeedBaccaratA.png",
      alt: "",
      name: "Speed Baccarat A",
      providerName: "Evolution",
    },
    GoldenWealthBaccarat: {
      redirectUrl: "/casino/ezgevo-golden-wealth-baccarat",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/baccarat/goldenwealthbaccarat.png",
      alt: "",
      name: "Golden Wealth Baccarat",
      providerName: "Evolution",
    },
    LightningBaccarat: {
      redirectUrl: "/casino/ezgevo-lightning-baccarat",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/baccarat/lightningbaccarat.png",
      alt: "",
      name: "Lightning Baccarat",
      providerName: "Evolution",
    },
    SpeedBaccaratA: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-a",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/baccarat/SpeedBaccaratA.png",
      alt: "",
      name: "Speed Baccarat A",
      providerName: "Evolution",
    },
    SpeedBaccaratB: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-b",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/baccarat/SpeedBaccaratB.png",
      alt: "",
      name: "Speed Baccarat B",
      providerName: "Evolution",
    },
    SpeedBaccaratC: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-c",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/baccarat/SpeedBaccaratC.png",
      alt: "",
      name: "Speed Baccarat C",
      providerName: "Evolution",
    },
    SpeedBaccaratD: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-d",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/baccarat/SpeedBaccaratD.png",
      alt: "",
      name: "Speed Baccarat D",
      providerName: "Evolution",
    },
    SpeedBaccaratE: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-e",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/baccarat/SpeedBaccaratE.png",
      alt: "",
      name: "Speed Baccarat E",
      providerName: "Evolution",
    },
    SpeedBaccaratF: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-f",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/baccarat/SpeedBaccaratF.png",
      alt: "",
      name: "Speed Baccarat F",
      providerName: "Evolution",
    },
    BaccaratSqueeze: {
      redirectUrl: "/casino/ezgevo-baccarat-squeeze",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/baccarat/baccaratsqueeze.png",
      alt: "",
      name: "Baccarat Squeeze",
      providerName: "Evolution",
    },
    BaccaratSqueeze: {
      redirectUrl: "/casino/ezgevo-baccarat-squeeze",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/baccarat/baccaratsqueeze.png",
      alt: "",
      name: "Baccarat Squeeze",
      providerName: "Evolution",
    },
  },

  blackjacktab: {
    VIPDiamondBlackjack: {
      redirectUrl: "/casino/ezg-vip-diamond-blackjack",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/VIPDiamondBlackjack.png",
      alt: "",
      name: "VIP Diamond Blackjack",
      providerName: "Ezugi",
    },
    UnlimitedBlackjack: {
      redirectUrl: "/casino/ezg-unlimited-blackjack",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/blackjack/unlimitedblackjack.png",
      alt: "",
      name: "Unlimited Blackjack",
      providerName: "Ezugi",
    },
    GoldBlackjack4: {
      redirectUrl: "/casino/ezg-gold-blackjack-4",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/blackjack/goldblackjack4.png",
      alt: "",
      name: "Gold Blackjack 4",
      providerName: "Ezugi",
    },
    GoldBlackjack6: {
      redirectUrl: "/casino/ezg-gold-blackjack-6",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/blackjack/goldblackjack6.png",
      alt: "",
      name: "Gold Blackjack 6",
      providerName: "Ezugi",
    },
    PlatiniumBlackjack1: {
      redirectUrl: "/casino/ezg-platinium-blackjack-1",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/blackjack/platinumblackjack1.png",
      alt: "",
      name: "Platinium Blackjack 1",
      providerName: "Ezugi",
    },
    GoldBlackjack1: {
      redirectUrl: "/casino/ezg-gold-blackjack-1",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/blackjack/goldblackjack1.png",
      alt: "",
      name: "Gold Blackjack 1",
      providerName: "Ezugi",
    },
    GoldBlackjack3: {
      redirectUrl: "/casino/ezg-gold-blackjack-3",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/blackjack/BlackjackGold3.png",
      alt: "",
      name: "Gold Blackjack 3",
      providerName: "Ezugi",
    },
    GoldBlackjack5: {
      redirectUrl: "/casino/ezg-gold-blackjack-5",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/blackjack/BlackjackGold5.png",
      alt: "",
      name: "Gold Blackjack 5",
      providerName: "Ezugi",
    },
    SalonPriveBlackjack: {
      redirectUrl: "/casino/ezg-salon-prive-blackjack",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/blackjack/BlackjackSalonPrivé.png",
      alt: "",
      name: "Salon Prive Blackjack",
      providerName: "Ezugi",
    },
    VIPDiamondBlackjack: {
      redirectUrl: "/casino/ezg-vip-diamond-blackjack",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/blackjack/vipdiamondblackjack.png",
      alt: "",
      name: "VIP Diamond Blackjack",
      providerName: "Ezugi",
    },
    TurkishBlackjack2: {
      redirectUrl: "/casino/ezg-turkish-blackjack-2",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/blackjack/turkishblackjack2.png",
      alt: "",
      name: "Turkish Blackjack 2",
      providerName: "Ezugi",
    },
    TurkishBlackjack1: {
      redirectUrl: "/casino/ezg-turkish-blackjack-1",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/blackjack/turkishbalckjack1.png",
      alt: "",
      name: "Turkish Blackjack 1",
      providerName: "Ezugi",
    },

    // evolution
    SpeedVIPBlackjackI: {
      redirectUrl: "/casino/ezgevo-speed-vip-blackjack-i",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/blackjack/speedvipblackjack1.png",
      alt: "",
      name: "Speed VIP Blackjack I",
      providerName: "Evolution",
    },
    FirstPersonBlackjack: {
      redirectUrl: "/casino/ezgevo-first-person-blackjack-u7366",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/blackjack/Firtperonblackjack.png",
      alt: "",
      name: "First Person Blackjack",
      providerName: "Evolution",
    },
    ClassicSpeedBlackjack57: {
      redirectUrl: "/casino/ezgevo-classic-speed-blackjack-57",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl:
        "./images/casino/evolution/blackjack/Classic-Speed-Blackjack-57.png",
      alt: "",
      name: "Classic Speed Blackjack 57",
      providerName: "Evolution",
    },
    FreeBetBlackjack3: {
      redirectUrl: "/casino/ezgevo-free-bet-blackjack-3",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/blackjack/Free-Bet-Blackjack-3.png",
      alt: "",
      name: "Free Bet Blackjack 3",
      providerName: "Evolution",
    },
    InfiniteBlackjack: {
      redirectUrl: "/casino/ezgevo-infinite-blackjack",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/blackjack/infiniteblackjack.png",
      alt: "",
      name: "Infinite Blackjack",
      providerName: "Evolution",
    },
    Craps: {
      redirectUrl: "/casino/ezgevo-craps",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/blackjack/craps.png",
      alt: "",
      name: "Craps",
      providerName: "Evolution",
    },
    FreeBetBlackjack4: {
      redirectUrl: "/casino/ezgevo-free-bet-blackjack-4",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/blackjack/freebet-blackjack-4.png",
      alt: "",
      name: "Free Bet Blackjack 2",
      providerName: "Evolution",
    },
    ClassicSpeedBlackjack55: {
      redirectUrl: "/casino/ezgevo-classic-speed-blackjack-55",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl:
        "./images/casino/evolution/blackjack/Classic-Speed-Blackjack-55.png",
      alt: "",
      name: "Classic Speed Blackjack 55",
      providerName: "Evolution",
    },
    ClassicSpeedBlackjack53: {
      redirectUrl: "/casino/ezgevo-classic-speed-blackjack-53",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl:
        "./images/casino/evolution/blackjack/Classic-Speed-Blackjack-53.png",
      alt: "",
      name: "Classic Speed Blackjack 53",
      providerName: "Evolution",
    },
  },

  GameShows: {
    CrazyTime: {
      redirectUrl: "/casino/ezgevo-crazy-time",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/populargames/crazytime.png",
      alt: "",
      name: "Crazy Time",
      providerName: "Evolution",
    },
    Megaball: {
      redirectUrl: "/casino/ezgevo-mega-ball",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/populargames/Megaball.png",
      alt: "",
      name: "Mega Ball",
      providerName: "Evolution",
    },
    LightningDice: {
      redirectUrl: "/casino/ezgevo-lightning-dice",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/populargames/LightningDice.png",
      alt: "",
      name: "Lightning Dice",
      providerName: "Evolution",
    },
    DreamCatcher: {
      redirectUrl: "/casino/ezgevo-dream-catcher",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/populargames/Dreamcatcher.png",
      alt: "",
      name: "Dream Catcher",
      providerName: "Evolution",
    },
    DeadorAlive: {
      redirectUrl: "/casino/ezgevo-dead-or-alive-saloon",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/populargames/Deadoralivesaloon.png",
      alt: "",
      name: "Dead or Alive: Saloon",
      providerName: "Evolution",
    },
    CashorCrash: {
      redirectUrl: "/casino/ezgevo-cash-or-crash",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/gameshows/cashorcrash.png",
      alt: "",
      name: "Cash or Crash",
      providerName: "Evolution",
    },
    FootballStudio: {
      redirectUrl: "/casino/ezgevo-football-studio",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/gameshows/FootballStudio.png",
      alt: "",
      name: "Football Studio",
      providerName: "Evolution",
    },
  },

  pokertab: {
    BetonTeenPatti: {
      redirectUrl: "/casino/ezg-bet-on-teen-patti",
      code: "aviator",
      casino: "spribe",
      provider: "aviator",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/BetonTeenPAtti.png",
      alt: "",
      name: "Bet on Teen Patti",
      providerName: "Ezugi",
    },
    TeenPatti3Card: {
      redirectUrl: "/casino/ezg-teen-patti-3-card",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/TeenPatti3Card.png",
      alt: "",
      name: "Teen Patti 3 Card",
      providerName: "Ezugi",
    },
    OneDayTeenPatti: {
      redirectUrl: "/casino/ezg-one-day-teen-patti",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/OneDayTeenPAtti.png",
      alt: "",
      name: "One Day Teen Patti",
      providerName: "Ezugi",
    },
    CasinoHoldem: {
      redirectUrl: "/casino/ezg-casino-holdem",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/CasinoHoldem.png",
      alt: "",
      name: "Casino Holdem",
      providerName: "Ezugi",
    },

    // evolution
    HandCasinoHoldem2: {
      redirectUrl: "/casino/ezgevo-2-hand-casino-holdem",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/poker/2handcasinoholdem.png",
      alt: "",
      name: "2 Hand Casino Holdem",
      providerName: "Evolution",
    },
    UltimateTexasHoldem: {
      redirectUrl: "/casino/ezgevo-ultimate-texas-holdem",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/poker/ultimatetexasholdem.png",
      alt: "",
      name: "Ultimate Texas Hold'em",
      providerName: "Evolution",
    },
    ThreeCardPoker: {
      redirectUrl: "/casino/ezgevo-three-card-poker",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/poker/threecardpoker.png",
      alt: "",
      name: "Three Card Poker",
      providerName: "Evolution",
    },
    TexasHoldemBonusPoker: {
      redirectUrl: "/casino/ezgevo-texas-holdem-bonus-poker",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/poker/texasholdembonuspoker.png",
      alt: "",
      name: "Texas Hold'em Bonus Poker",
      providerName: "Evolution",
    },
    SidebetCity: {
      redirectUrl: "/casino/ezgevo-side-bet-city",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/poker/sidebetcity.png",
      alt: "",
      name: "Side Bet City",
      providerName: "Evolution",
    },
    CasinoHolemEvo: {
      redirectUrl: "/casino/ezgevo-casino-holdem-evo",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/poker/casinoholdem.png",
      alt: "",
      name: "Casino Hold'em-EVO",
      providerName: "Evolution",
    },
  },

  sicbotab: {
    UltimateSicBo: {
      redirectUrl: "/casino/ezg-ultimate-sic-bo",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/UltimateSicbo.png",
      alt: "",
      name: "Ultimate Sic Bo",
      providerName: "Ezugi",
    },
    SicBo: {
      redirectUrl: "/casino/ezg-sic-bo",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/sicbo/sicbo.png",
      alt: "",
      name: "Sic Bo",
      providerName: "Ezugi",
    },
    CricketWar: {
      redirectUrl: "/casino/ezg-cricket-war",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/populargames/CricketWar.png",
      alt: "",
      name: "CricketWar",
      providerName: "Ezugi",
    },

    // evolution
    SuperSicBo: {
      redirectUrl: "/casino/ezgevo-super-sic-bo",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/evolution/populargames/Supersicbo.png",
      alt: "",
      name: "Super Sic Bo",
      providerName: "Evolution",
    },
  },

  indianGames: {
    Lucky7: {
      redirectUrl: "/casino/ezg-lucky-7",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/indiangames/lucky7.png",
      alt: "",
      name: "Lucky7",
      providerName: "Ezugi",
    },
    Cards32: {
      redirectUrl: "/casino/ezg-32-cards",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/casino/ezugi/indiangames/32cards.png",
      alt: "",
      name: "32 Cards",
      providerName: "Ezugi",
    },
  },
};
